import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopNav from 'components/navigation/top-nav';
import Footer from 'components/navigation/footer';
import FullPageLoader from 'components/throbber/loader';
import AccountManager from 'managers/Account';
import SimpleModal from 'components/modal/modal';
import CreatorManager from 'managers/Creator';
import CookieConsent from 'react-cookie-consent';
import moment from 'moment-mini';
import { getParameterByName } from 'managers/Helpers';
import { get } from 'jquery';
import ConfirmTransfer from './components/transfer/confirmTransfer';
import RouterListener from './components/router/routerListener';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      dropDetails: null,
      showContent: false,
      showModal: false,
      mode: '',
      currentDate: moment().unix() * 1000,
      isFreeTransfer: false,
      navType: 'default',
      appPath: '',
    };
  }

  getAccountDetails = async () => {
    await AccountManager.get(AccountManager.getToken());
  }

  cancelTransfer = (transferToken) => {
    const { isFreeTransfer } = this.state;
    if (isFreeTransfer) {
      localStorage.removeItem(process.env.CREATOR_SUBDOMAIN + 'FreeToken');
      localStorage.removeItem(process.env.CREATOR_SUBDOMAIN + 'Countdown');
      this.setState({
        showModal: false,
      });
    } else {
      localStorage.removeItem('MEMBERSHIP_TRANSFER_' + transferToken);
      // remove query string if it exists
      if (window.location.href.includes('?')) {
        window.location.href = window.location.href.split('?')[0];
      } else {
        window.location.href = '/';
      }
    }
  }

  onTransferError = (transferToken, message) => {
    toast.error(message);
    localStorage.removeItem('MEMBERSHIP_TRANSFER_' + transferToken);
    this.setState({
      showModal: false,
    });
  }

  transferNFT = async (transferToken, isFree, transferDetails, transferType) => {
    const payload = {
      transferToken,
    };
    let verified;
    if (isFree) {
      verified = await AccountManager.transferFreeNFt(AccountManager.getToken(), payload);
    } else if (transferType === 'unlimitedEdrop') {
      verified = await AccountManager.transUnlimitedEdrop(AccountManager.getToken(), payload);
    } else if (transferType === 'eDrop') {
      verified = await AccountManager.transferEdrop(AccountManager.getToken(), payload);
    } else {
      verified = await AccountManager.transferNFt(AccountManager.getToken(), payload);
    }
    if (verified && verified.success) {
      if (isFree) {
        localStorage.removeItem(process.env.CREATOR_SUBDOMAIN + 'FreeToken');
        localStorage.setItem(process.env.CREATOR_SUBDOMAIN + 'PreRegTokenClaimed', true);
      } else {
        localStorage.removeItem('MEMBERSHIP_TRANSFER_' + transferToken);
      }
      gtag('event', process.env.CREATOR_SUBDOMAIN + '_edrop', { // eslint-disable-line
        event_category: process.env.CREATOR_SUBDOMAIN + '_edrop_success',
        event_label: 'User transferred a ' + process.env.CREATOR_SUBDOMAIN + ' eDrop NFT',
      });
      // localStorage.setItem('NFT_TOKEN_TRANSFERRED_' + transferToken, transferToken);

      // TODO: add syncing if it's transferType === 'unlimitedEdrop'
      if (process.env.MEMBERSHIP_AUTO_SYNC && process.env.MEMBERSHIP_AUTO_SYNC === 'true' && transferType === 'unlimitedEdrop') {
        const syncPayload = {
          creatorId: transferDetails.creator.creatorId,
          assetId: transferDetails.asset.assetId,
        };
        const token = AccountManager.getToken();
        const syncResponse = await AccountManager.syncMembership(token, syncPayload);
        console.log('=== sync results ===');
        console.log(syncResponse);
      }

      this.setState({
        showModal: false,
      });
      const assetTypeString = process.env.DIGITAL_ASSET_TYPE || 'digital asset';
      // TODO: consider this at a later date
      // toast.success('Successfully completed transfer. Your membership should now be available on your collection page.');
    } else {
      localStorage.removeItem('MEMBERSHIP_TRANSFER_' + transferToken);
      this.setState({
        showModal: false,
      });
      // removing this as the user doesn't need to see it.
      // toast.error(verified && verified.message ? verified.message : 'Sorry. This transfer is no longer available.');
    }
  }

  findTransferKey = () => {
    let nftToken = null;
    let tokenType = null;
    Object.entries(localStorage).map((obj) => {
      const key = obj[0];
      if (key.includes('MEMBERSHIP_TRANSFER')) {
        nftToken = key;
        tokenType = 'transfer';
      }
      return true;
    });
    Object.entries(localStorage).map((obj) => {
      const key = obj[0];
      if (key.includes('FreeToken')) {
        if (obj[1] !== 'already claimed') {
          nftToken = key;
          tokenType = 'free';
        }
      }
      return true;
    });
    return {
      token: nftToken,
      tokenType,
    };
  }

  getDropData = async () => {
    const that = this;
    const { currentDate } = this.state;
    const dropData = await CreatorManager.getDrop();
    let countdownDate;
    // Check if dropData.drop has a countdown set. If it doesn, always display the countdown page
    if (dropData.drop.countDown && dropData.drop.countDown.countDownDate) {
      countdownDate = moment(dropData.drop.countDown.countDownDate).unix() * 1000;
    }
    if (countdownDate > currentDate && (!window.location.href.includes('login') && !window.location.href.includes('reset') && !window.location.href.includes('register') && !window.location.href.includes('account'))) {
      window.location.pathname = '/countdown';
    } else if (dropData) {
      that.setState({
        dropDetails: dropData,
        showContent: true,
        isLoading: false,
      });
    }
  }

  checkForRiskified = () => {
    const that = this;
    if (typeof RISKX === 'undefined') {
      console.log('RISKX failed to initialize.');
      setTimeout(() => {
        that.checkForRiskified();
      }, 100);
    } else {
      AccountManager.setSessionId();
    }
  }

  updateUrlState = (url) => {
    // use this to pass url specific props to global components
    if (url.includes('edrop')) {
      this.setState({
        navType: 'storefront',
        appPath: url,
      });
    } else {
      this.setState({
        navType: 'default',
        appPath: url,
      });
    }
  }

  componentDidMount = async () => {
    this.checkForRiskified();

    if (getParameterByName('referralCode')) {
      sessionStorage.setItem('ethos_referralCode', getParameterByName('referralCode'));
    }

    if (Weglot && getParameterByName('lang')) {
      Weglot.switchTo(getParameterByName('lang'));
    }

    // check if the user is logged in. If they are, grab the account details
    let transferKey = null;
    // check if this transferCode has already been claimed
    let alreadyClaimed = false;
    if (AccountManager.isLoggedIn()) {
      if (this.findTransferKey()) {
        transferKey = this.findTransferKey();
        if (localStorage.getItem('NFT_TOKEN_TRANSFERRED_' + sessionStorage.getItem(transferKey.token))) {
          alreadyClaimed = true;
        }
        if (!alreadyClaimed) {
          if (transferKey.tokenType === 'transfer') {
            this.setState({
              transferToken: localStorage.getItem(transferKey.token),
            });
            if (localStorage.getItem(transferKey.token)) {
              this.setState({
                showModal: true,
                mode: 'confirmTransfer',
                isFreeTransfer: transferKey.tokenType === 'free',
              });
            }
          } else {
            this.setState({
              transferToken: localStorage.getItem(transferKey.token),
            });
            if (localStorage.getItem(transferKey.token)) {
              this.setState({
                showModal: true,
                mode: 'confirmTransfer',
                isFreeTransfer: transferKey.tokenType === 'free',
              });
            }
          }
        } else {
          sessionStorage.removeItem(transferKey.token);
        }
      }
      await this.getAccountDetails();
    }

    // TODO: Get the drop details now
    if (process.env.E_DROP_ONLY !== 'true') {
      await this.getDropData();
    } else {
      // TODO: Get the creator details now
      // const creator = await CreatorManager.getCreatorData();
      this.setState({
        showContent: true,
        isLoading: false,
      });
    }
  }

  render() {
    const {
      appPath, isFreeTransfer, isLoading, dropDetails, showContent, showModal, mode, transferToken, navType,
    } = this.state;
    return (
      <div>
        <RouterListener onUrlChange={(url) => this.updateUrlState(url)} />
        <SimpleModal isOpen={showModal} onBeforeClose={() => this.setState({ showModal: false })}>
          {mode === 'confirmTransfer'
            && (
            <ConfirmTransfer
                  isFree={isFreeTransfer}
                  nftToken={transferToken}
                  onConfirm={(token, isFree, transferDetails, transferType) => this.transferNFT(token, isFree, transferDetails, transferType)}
                  onCancel={(token) => this.cancelTransfer(token)}
                  onError={(token, message) => this.onTransferError(token, message)} />
            )}
        </SimpleModal>
        {isLoading
          ? (
            <div>
              <FullPageLoader />
            </div>
          )
          : (
            <div>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                pauseOnHover
                theme="light" />
              <TopNav
                dropDetails={dropDetails}
                navType={navType} />
              {showContent && !showModal
                && <Outlet />}
              {process.env.CREATOR_SUBDOMAIN !== 'account'
              && <Footer url={appPath} />}
              <CookieConsent
                cookieName={process.env.CREATOR_SUBDOMAIN + 'CookieConsent'}>
                <a href="https://www.heyethos.com">ethos</a>
                {' '}
                uses cookies to provide necessary website functionality,
                improve your experience and analyze our traffic. By using our website, you agree to&nbsp;
                <a target="_blank" href="https://www.heyethos.com/privacy" rel="noreferrer">our privacy policy</a>
                {' '}
                and our cookies usage.
                
              </CookieConsent>
            </div>
          )}
      </div>
    );
  }
}

export default Container;
